@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles */
.prose img {
  @apply rounded-lg shadow-md;
}

.prose a {
  @apply text-blue-600 hover:text-blue-800;
}